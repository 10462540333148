body{
  background-color: hsl(220, 33%, 98%);
height: auto;
font-family: 'Inter', sans-serif;
padding: 80px 0px 80px 0px;
}

.window{
  width: 70%;
  height: auto;
  border-radius: 20px;
  background-color: white;
  margin: auto;
  padding:30px;

}

img{
border-radius: 50%;
height:120px;
width:120px;
margin-right: 50px;
}

.header{
  display: flex;
  flex-direction: row;
}

h4, .grey-text, th{
  color: hsl(0, 0%, 45%);
  font-size:18px;
font-weight: 300;
}

 

.header-data{
  display:flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  font-weight: 300;
}

/* atstarpes starp telefona nr, epastu, adresi utt */
.header-data > span{
margin-right: 20px;
}

/* atstarpe starp ikonu un tekstu  */
span > i{
  margin-right:10px;
}

/* pelēkā svītra zdm katras sdaļas */
.description, .header, .jobs, .education, .languages, .technologies, .licence{
  padding-bottom: 20px;
  border-bottom: 1px solid hsl(220, 33%, 88%);
}

.jobs > p,  .education > p, .courses > div > p, .education>div>p, .jobs>div>.grey-text{
  margin: 0;
}

/* paragrāfs bez margin jobs sadaļā un description sadaļai lai noņem apakšējo margin paragrāfam*/
.jobs > h3, .description > p,  .courses>div>h3, .education>div>h3, .jobs>div>h3{
  margin-bottom: 5px;
}

.jobs>div>div{
  margin-top: 10px;
}

tr{
  width: 100%;
}
th{
  width: 400px;
}
td{
  text-align: center;
}
tr> td:first-child{
  font-weight: 300;
  text-align: left;
}


th:first-child{
  text-align: left;

}
/* tehnoloģijas vienā līnijā */
li{
  display: inline-block;
  margin:2px;
  padding-top: 3px;
} 

span> a{
  color: black;
 text-decoration: none;
}
a> i{
  margin-right: 10px;
}

/* bumbiņas starp list itemeim */
li::before{
content: " ";
display: inline-block;

margin: 2.5px 7px 2.5px 7px;
width: 6px;
height: 6px;
-moz-border-radius: 7.5px;
-webkit-border-radius: 7.5px;
border-radius: 7.5px;
background-color: hsl(220, 33%, 88%);
}